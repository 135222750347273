.background {
	background-position: center;
	background-size: cover;
	position: fixed;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100vw;
	height: 100vh;
	z-index: 0;
}

.background::before {
	background-color: var(--color-black);
	content: "";
	display: block;
	opacity: 0.5;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
