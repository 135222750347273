.theme-blue {
	background-color: var(--color-blue);
}

.theme-green {
	background-color: var(--color-green);
}

.theme-light-grey {
	background-color: var(--color-grey-light);
}

.theme-orange {
	background-color: var(--color-orange);
}

.theme-yellow {
	background-color: var(--color-yellow);
}

.theme-white {
	background-color: var(--color-white);
}

.theme-blue,
.theme-green,
.theme-orange,
.theme-yellow {
	color: var(--color-white);
}

.theme-light-grey,
.theme-white {
	color: var(--color-black);
}

.theme-color-orange {
	color: var(--color-orange);
}
