html {
	font-family: var(--font-sans);
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
}

@media (min-width: 768px) {
	html {
		font-size: 20px;
	}
}

h1 {
	font-size: 2.5rem;
	font-weight: 700;
	margin-top: 0;
	text-transform: uppercase;
}

@media (min-width: 568px) {
	h1 {
		font-size: 4rem;
	}
}

h2 {
	font-size: 1.5rem;
	font-weight: 700;
	margin-top: 0;
	text-transform: uppercase;
}

h2:not([class]) {
	margin-top: 4rem;
	margin-bottom: 3.5rem;
}

h3 {
	font-size: 0.9rem;
	font-weight: 700;
	letter-spacing: 0.05em;
	margin-top: 0;
	margin-bottom: 2rem;
	text-transform: uppercase;
}

h4 {
	font-size: 1rem;
	font-weight: 700;
	margin-top: 0;
}

h5 {
	font-size: 0.8rem;
	font-weight: 700;
	letter-spacing: 0.05em;
	margin-top: 0;
	text-transform: uppercase;
}

p {
	line-height: 1.5;
	margin-bottom: 2rem;
}

p:last-child {
	margin-bottom: 0;
}

a {
	border-bottom: 1px solid var(--color-alpha-blue-25);
	color: var(--color-blue);
	padding-right: 2px;
	padding-left: 2px;
	text-decoration: none;
	transition:
		color 128ms ease-in-out,
		border-color 128ms ease-in-out;
}

a:hover {
	border-color: var(--color-blue);
	color: var(--color-blue);
}
