button::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.form-input {
	appearance: none;
	background-color: transparent;
	border: 1px solid var(--color-grey);
	border-radius: 3px;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-foreground);
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	outline: none;
	padding: 0.7rem 0.75rem 0.6rem;
	position: relative;
	transition: border-color 256ms ease-in-out;
	width: 100%;
}

@media (min-width: 1025px) {
	.form-input {
		font-size: 1rem;
	}
}

.form-input::placeholder {
	color: var(--color-grey);
}

.form-input:focus {
	border-color: var(--color-grey);
}

.form-input[disabled],
.is-disabled .form-input {
	cursor: not-allowed;
	opacity: 0.5;
}

.form-input.is-dirty:invalid {
	border-color: var(--color-red);
}

.form.is-submitted .form-input:invalid {
	border-color: var(--color-red);
}

.form-input.is-dirty:invalid::placeholder {
	color: var(--color-red);
}

.form.is-submitted .form-input:invalid::placeholder {
	color: var(--color-red);
}

.form-input.is-inline {
	width: auto;
}

.form-label {
	color: var(--color-foreground);
	display: inline-block;
	font-size: 1rem;
	line-height: 1;
	transition: color 256ms;
	width: 100%;
}

.is-disabled .form-label {
	color: gray;
}

.form-label.is-inline {
	width: auto;
}

.form-group {
	font-size: 0;
	margin-bottom: 1.75rem;
}

.form-group .form-label {
	line-height: 2.5rem;
	vertical-align: top;
	width: 14rem;
}

.form-group .form-input {
	width: calc(100% - 14rem);
}

.form-group textarea.form-input {
	line-height: 1.5;
	height: 6rem;
}

.form-group .button {
	display: block;
	margin: 3rem auto 0;
}

@media (min-width: 768px) {
	.form-group .button {
		margin-top: 4rem;
	}
}

@media (max-width: 767px) {
	.form-group .form-label,
	.form-group .form-input {
		display: block;
		padding-left: 0.7rem;
		width: 100%;
	}

	.form-group .form-label {
		margin-bottom: 0.7rem;
	}
}

.form-group.form-group--field-note {
	font-size: 0.825rem;
	line-height: 1.4;
	padding-left: 0.7rem;
}

@media (min-width: 768px) {
	.form-group.form-group--field-note {
		box-sizing: border-box;
		padding-left: 14rem;
	}
}

/** Since this component is pretty fixed, we have to cheat
 * a bit here to alter the spacing. The following style places
 * the note closer to the field above, so it's more clear it
 * provides more information or context for that field.
 */

.form-group.form-group--field-note:not(:first-child) {
	margin-bottom: 1rem;
	transform: translateY(-0.75rem);
}

.form-footer {
	display: block;
	line-height: 1.5;
	text-align: center;
}

@media (min-width: 768px) {
	.form-checkbox {
		padding-left: 14rem;
	}
}

.form-checkbox input {
	display: none;
}

.form-checkbox input:checked + label::before {
	border-color: var(--color-orange);
}

.form-checkbox input:checked + label::after {
	display: block;
}

.form-checkbox label {
	cursor: pointer;
	display: block;
	font-size: 0.825rem;
	line-height: 1.4;
	padding-left: 1.5rem;
	position: relative;
	width: calc(100% - 1.5rem);
}

.form-checkbox label:hover::before {
	border-color: var(--color-grey);
}

.form-checkbox label::before {
	border: 1px solid #d1d1d1;
	border-radius: 3px;
	content: "";
	display: block;
	position: absolute;
	top: -1px;
	left: 0;
	width: 1rem;
	height: 1rem;
}

.form-checkbox label::after {
	background-color: var(--color-orange);
	border-radius: 2px;
	content: "";
	display: none;
	position: absolute;
	top: 3px;
	left: 4px;
	width: calc(1rem - 6px);
	height: calc(1rem - 6px);
}

.form-checkbox a {
	display: inline-block;
	font-size: 0.825rem;
	margin-top: 0.5rem;
	margin-left: 1.5rem;
}

.checkbox-group {
	display: inline-flex;
	flex-wrap: wrap;
	width: calc(100% - 14rem);
}

.checkbox-group .form-checkbox {
	margin-bottom: 0.5rem;
	padding-left: 0;
	width: 50%;
}

.form-group .checkbox-group {
	margin-top: 0.75rem;
}

.form-checkbox-group {
	margin-bottom: 0.75rem;
}

@media (min-width: 768px) {
	.form-checkbox-group {
		padding-top: 0.75rem;
	}
}

@media (min-width: 1024px) {
	.form-checkbox-group {
		display: flex;
	}
}

.form-checkbox-group .form-label {
	width: 100%;
}

@media (min-width: 1024px) {
	.form-checkbox-group .form-label {
		flex: 0 0 10rem;
	}
}

.form-checkbox-group .form-checkbox-group__list {
	width: 100%;
}

@media (min-width: 1024px) {
	.form-checkbox-group .form-checkbox-group__list {
		display: flex;
		flex: 0 0 calc(100% - 10rem);
		flex-wrap: wrap;
	}
}

@media (min-width: 1024px) {
	.form-checkbox-group .form-checkbox {
		margin: 0 1rem 1rem 0;
		padding: 0;
	}
}

.inline-form {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin: 1.25rem auto;
}

@media (min-width: 768px) {
	.inline-form {
		flex-wrap: nowrap;
	}
}

.inline-form .form-group {
	display: inline-block;
}

.inline-form .form-input {
	background-color: var(--color-white);
	border: 0;
	border-radius: 0;
	box-sizing: border-box;
	font-size: 1.25rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
	height: 5rem;
}

.inline-form .form-field {
	flex-basis: 100%;
	height: 5rem;
}

/* from-small,tablet-p
 */
@media (min-width: 768px) {
	.inline-form .form-field {
		flex-basis: 37.5%;
		flex-grow: 0;
		flex-shrink: 1;
	}

	.inline-form .form-field--half {
		flex-basis: 50%;
	}

	.inline-form .form-field--quarter {
		flex-basis: 25%;
	}

	.inline-form .form-field--3-quarter {
		flex-basis: 75%;
	}
}

.inline-form .button {
	border-radius: 0;
	box-sizing: border-box;
	font-size: 1.25rem;
	padding: 0;
	width: 100%;
	height: 100%;
}

.inline-form .alert {
	border-radius: 0;
	font-size: 1.25rem;
	font-weight: 700;
	margin-bottom: 0;
	position: absolute;
	text-transform: uppercase;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	z-index: 2;
}

.inline-form .alert.is-error {
	background-color: var(--color-white);
	color: var(--color-red);
}

.inline-form .alert.is-success {
	background-color: var(--color-white);
	color: var(--color-green);
}

.inline-form .alert__section {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	transform: translateY(-50%);
}

.form-fieldset {
	border: 0;
	padding: 0;
}

@media (min-width: 1024px) {
	.form-fieldset .form-group:not(.form-checkbox-group) {
		display: flex;
	}
}

.form-fieldset .form-group:not(.form-checkbox-group) label {
	margin-bottom: 0.625rem;
	width: 100%;
}

@media (min-width: 1024px) {
	.form-fieldset .form-group:not(.form-checkbox-group) label {
		flex: 0 0 10rem;
		line-height: 1;
		margin-bottom: 0;
		padding: 0.7rem 0 0.6rem;
	}
}

.form-fieldset .form-group:not(.form-checkbox-group) .form-input {
	width: 100%;
}

@media (min-width: 1024px) {
	.form-fieldset .form-group:not(.form-checkbox-group) .form-input {
		flex: 0 0 calc(100% - 10rem);
	}
}

.form-fieldset .form-group:not(.form-checkbox-group) textarea {
	line-height: 1.5;
	height: 200px;
}

.form-description {
	color: var(--color-grey);
	font-size: 1rem;
	margin-bottom: 2rem;
}

@media (min-width: 1024px) {
	.form-description {
		padding-left: 10rem;
	}
}

.form-wysiwyg,
.mce-edit-area iframe {
	min-height: 400px;
}
