.modal {
	background-color: var(--color-grey);
	color: var(--color-white);
	display: block;
	padding: 1.5rem;
	position: relative;
	transition: transform 256ms ease-in-out;
	will-change: transform;
}

.modal[hidden] {
	transform: translateY(-100%);
}

.modal--success {
	background-color: var(--color-green);
}

.modal--error {
	background-color: var(--color-red);
}

.modal__close {
	appearance: none;
	background: transparent;
	border: none;
	color: var(--color-white);
	font-family: sans-serif;
	font-size: 1rem;
	font-weight: 100;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 2px; /* Visually correct line height */
	width: 4rem;
}

.modal__close:active,
.modal__close:focus {
	outline: 0;
}
