.u-clearfix::after {
	clear: both;
	content: " ";
	display: table;
}

/* Study360 legacy - please don't do this anymore */
@media (max-width: 767px) {
	.u-hide-mobile {
		display: none;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.u-hide-tablet {
		display: none;
	}
}

@media (min-width: 1024px) {
	.u-hide-desktop {
		display: none;
	}
}

.u-centered {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}
