.l-checkin {
	padding: 0;
}

.l-checkin__notice {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
}

.l-checkin__notice:empty {
	display: none;
}

.l-checkin__container {
	background-color: var(--color-blue);
	background-position: center;
	background-size: cover;
	color: var(--color-white);
	padding: 0;
	position: relative;
	text-align: center;
	height: 100%;
	min-height: 100%;
}

.l-checkin__container::before {
	background-color: rgb(0 0 0 / 0.5);
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.l-checkin__content {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	transform: translateY(-50%);
}

.l-checkin__content h1 {
	font-size: 2rem;
}

.l-checkin__content p {
	font-weight: 700;
	text-shadow: 0 0 8px rgb(0 0 0 / 0.15);
}

.l-checkin__footer {
	background-image: url("../images/antwerpen.svg");
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 3.5rem;
	height: 3.5rem;
}

.l-checkin__language-selector {
	font-size: 0.7rem;
	line-height: 3.125rem;
	position: absolute;
	text-transform: uppercase;
	top: 0;
	right: 0.75rem;
	z-index: 1;
}

.l-checkin__language-selector a {
	border: 0;
	color: rgb(255 255 255 / 0.5);
	cursor: pointer;
	display: inline-block;
	padding: 0.5rem 0.75rem;
}

.l-checkin__language-selector a.is-active {
	color: rgb(255 255 255 / 1);
	font-weight: 700;
}

.l-checkin__back {
	border: 0;
	color: rgb(255 255 255 / 0.5);
	cursor: pointer;
	font-size: 0.7rem;
	font-weight: 700;
	line-height: 3.125rem;
	padding: 0.5rem 1rem;
	position: absolute;
	text-transform: uppercase;
	top: 0;
	left: 0.5rem;
}

.l-checkin__back::before {
	content: "❮";
	font-size: 0.75em;
	font-weight: 100;
	margin-right: 0.15rem;
	vertical-align: middle;
}

.l-checkin__location-plan {
	background-color: #231f20;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: 100vw;
	height: 100vh;
}

.l-checkin__gate-15 {
	background-image: url("../images/powered-by-stan.svg");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	position: absolute;
	bottom: 0.75rem;
	left: 0.75rem;
	width: 9rem;
	height: 3rem;
}

.l-checkin__location-logo {
	background-position: right center;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	position: absolute;
	right: 1.5rem;
	bottom: 1.5rem;
	width: 9rem;
	height: 3rem;
}
