:root {
	--color-background: #ffffff;
	--color-foreground: #000000;
	/*  */
	--color-black: #000000;
	--color-blue: #0000ff;
	--color-green: #00dd00;
	--color-grey: #c3c3c3;
	--color-grey-light: #f3f3f3;
	--color-orange: #eb5d0b;
	--color-red: #e81830;
	--color-white: #ffffff;
	--color-yellow: #f8cd02;
	/*  */
	--color-alpha-blue-25: rgb(0 0 255 / 0.25);
	--color-alpha-orange-0: rgb(235 93 11 / 0);
	--color-alpha-orange-25: rgb(235 93 11 / 0.25);
	/*  */
	--font-sans: "ff-din-web", sans-serif;
	--font-serif: "Georgia", serif;
}
