.wrapper {
	margin: 0 auto;
	position: relative;
	width: 90%;
}

@media (min-width: 1024px) {
	.wrapper {
		max-width: 920px;
	}
}

@media (min-width: 1380px) {
	.wrapper {
		max-width: 1230px;
	}
}

@media (min-width: 768px) {
	.wrapper.is-small {
		width: 60%;
	}
}

@media (min-width: 1024px) {
	.wrapper.is-small {
		max-width: 534px;
	}
}

@media (min-width: 1380px) {
	.wrapper.is-small {
		max-width: 720px;
	}
}

@media (min-width: 768px) {
	.wrapper.is-medium {
		width: 75%;
	}
}

@media (min-width: 1024px) {
	.wrapper.is-medium {
		max-width: 720px;
	}
}

@media (min-width: 1380px) {
	.wrapper.is-medium {
		max-width: 920px;
	}
}
